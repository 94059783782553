<template>
    <div class="login-wrap">
        <router-link to="/" class="login-logo">
            <img src="../assets/images/logo.svg" alt="logo"/>
        </router-link>
        <form class="login-form form" @submit.prevent="login" v-if="!token">
            <h3 class="form-title">Платформа депутата</h3>
            <div class="login-img">
                <img src="../assets/images/login.svg" alt="login-pic">
            </div>
            <div class="form-control">
                <input
                    class="login-form__input"
                    type="text"
                    ref="card_id"
                    placeholder="Номер партійного квитка"
                    tabindex="1"
                    name="card_id"
                    v-model="form.card_id"
                />
                <span class="error-msg" v-if="errors.card_id">{{errors.card_id}}</span>
            </div>
            <div class="login-form__field form-control" style="position: relative">
                <input
                    class="login-form__input"
                    :type="isShowPassword ? 'text': 'password'"
                    v-model="form.password"
                    name="password"
                    placeholder="Пароль"
                    tabindex="2"
                />
                <div @click="isShowPassword = !isShowPassword" class="show-password">
                    <span v-if="!isShowPassword">
                        <img src="../assets/images/visibility_off.svg" alt="visibility"/>
                    </span>
                    <span v-else>
                        <img src="../assets/images/visibility-pass.svg" alt="visibility"/>
                    </span>
                </div>
                <span class="error-msg" v-if="errors.password">{{errors.password}}</span>
            </div>
            <div class="restore">
                <span class="info-ic">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.33301 3.66659H7.66634V4.99992H6.33301V3.66659ZM6.33301 6.33325H7.66634V10.3333H6.33301V6.33325ZM6.99967 0.333252C3.31967 0.333252 0.333008 3.31992 0.333008 6.99992C0.333008 10.6799 3.31967 13.6666 6.99967 13.6666C10.6797 13.6666 13.6663 10.6799 13.6663 6.99992C13.6663 3.31992 10.6797 0.333252 6.99967 0.333252ZM6.99967 12.3333C4.05967 12.3333 1.66634 9.93992 1.66634 6.99992C1.66634 4.05992 4.05967 1.66659 6.99967 1.66659C9.93967 1.66659 12.333 4.05992 12.333 6.99992C12.333 9.93992 9.93967 12.3333 6.99967 12.3333Z" fill="#FFC900"/>
                    </svg>
                </span>
               <span class="restore-label">Забули пароль?</span>
               <router-link to="/remind-password" class="restore-link" href="#">Нагадати</router-link>
           </div>
            <div class="login-form__actions">
               <CustomButton label="Підтвердити" type="submit"/>
            </div>
        </form>
        <SmsCodeForm v-if="token" :token="token"/>
    </div>
</template>

<script>
import SmsCodeForm from '../components/SmsCodeForm';
import AuthService from "@/services/AuthService";
import CustomButton from "@/components/CustomButton";

import Vue from "vue";
import axios from "axios";
import { VueReCaptcha } from "vue-recaptcha-v3";


export default {
    data() {
        return {
            token: '',
            isShowPassword: false,
            errors: {
                card_id: '',
                password: ''
            },
            form: {
                password: "",
                card_id: "",
            },
            recaptcha: ''
        };
    },
    async created() {
        await this.$recaptchaLoaded();
    },
    components: {SmsCodeForm, CustomButton},
    methods: {
        catchHandler(resp) {
            if(resp.statusCode === 422) {
                this.errors = resp.data;
            }
        },
        async login() {
            let form = new FormData();

            form.append('password', this.form.password);
            form.append('card_id', this.form.card_id);

            this.recaptcha = await this.$recaptcha('login')
            form.append('recaptcha', this.recaptcha);

            let resp = await AuthService.login(form).catch(this.catchHandler);

            if(resp && resp.token) {
                this.token = resp.token;
            }
        },
        onCaptchaExpired: function () {
            this.$refs.recaptcha.reset();
        }
    }
};
</script>


<style scoped lang="less">
.info-ic {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-right: 10px;
    margin-bottom: 2px;
}
.login-form {
    width: 100%;
    margin-top: auto;

    .form-control {
        position: relative;
        margin-bottom: 54px;

        .error-msg {
            position: absolute;
            top: 100%;
            right: 0;
            color: #ffc900;
            font-size: 16px;
            text-align: right;
        }
    }

    &__input {
        width: 100%;
        height: 56px;
        border: 1px solid #ffc900;
        background: transparent;
        border-radius: 15px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #f5f5f5;
        padding-left: 20px;
        outline: none;
        transition: all 0.3s ease-in;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

        &:focus {
            color: #1a2744;
            background: #ffc900;
        }

        &::placeholder {
            color: #f5f5f5;
        }

        &:focus::placeholder {
            color: #1a2744;
        }

    }
}

.login-img {
    height: 220px;
    margin-bottom: 40px;
    img {
        width: 100%;
        height: 100%;
    }
}


@media screen and (max-height: 950px) {
    .login-img  {
        height: 170px;
        margin-bottom: 20px;
    }
    .login-form .form-control {
        margin-bottom: 20px;
    }
}

@media screen and (max-height: 850px) {
    .login-img  {
        height: 170px;
        margin-bottom: 20px;
    }
    .login-form .form-control {
        margin-bottom: 20px;
    }
    .login-wrap {
        overflow-y: auto;
    }
}

@media screen and (max-height: 700px) {
    .login-form .form-control {
        margin-bottom: 20px;
    }
    .login-form__input {
        height: 46px;
    }
}

@media screen and (max-width: 1200px)  {
    .login-form .form-control {
        margin-bottom: 20px;
    }
    .login-form__input {
        height: 50px;
    }
}

@media screen and (max-height: 900px) {
    .login-form .form-control {
        margin-bottom: 40px;
    }
}

@media screen and (max-height: 700px) {
    .login-form .form-control {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 992px) {
    .login-wrap {
        margin: 0 auto ;
    }
    .form-title {
        max-width: 100%;
    }
    .login-form {
        max-width: 100%;
    }
}
@media screen and (max-width: 768px) {

}

@media screen and (max-width: 480px) {
  .login-form {
    margin-top: 0 ;
  }
  .login-logo {
      height: 40px;
      min-height: 40px;
      width: 62px;
      overflow: hidden;
      margin-bottom: 44px;
      img {
        height: 40px;
        width: auto;
      }
  }
    .login-form .form-control {
        margin-bottom: 45px;
    }

  .login-form__input {
      height: 46px;
  }
  .login-img {
    display: none;
  }
  .custom-button {
        line-height: 1.2;
        font-size: 24px;
        width: 240px;
        height: 52px;
        &:after {
            top: 5%;
            margin-left: -78px;
            width: 149px;
            height: 50px;
        }
  }
}
</style>


<style>
::-webkit-credentials-auto-fill-button {
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
}
</style>
