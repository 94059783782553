<template>
    <section class="login">
        <div class="login-photo">
        </div>
        <LoginForm :title="title"/>
    </section>
</template>
<script>
import LoginForm from "@/components/LoginForm";

export default {
    name: "login",
    watch: {
        inputType: function (newVal, oldVal) {
            console.log(newVal, oldVal);
        },
    },
    data() {
        return {
            title: "",
            options: {
                licenseKey: "123123123",
                afterLoad: this.afterLoad,
                controlArrows: false,
            },
        };
    },
    components: {
        LoginForm,
    },
    methods: {
        getTitle(title) {
            this.title = title;
        },
        afterLoad(origin, destination, direction) {
            this.title = this.slides[destination.index].title;
        },
    },
};
</script>

<style lang="less">
.login {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: #1a2744;

    &-wrap {
        position: relative;
        max-width: 740px;
        width: 100%;
        height: 100vh;
        background: #1a2744;
        padding: 80px 100px 100px 100px;
        display: flex;
        flex-direction: column;
        /*overflow-y: auto; */
    }
}

.login-photo {
    background: #1a2744 url(../../assets/images/city.jpg) center left no-repeat;
    width: 100%;
    background-size: cover;
    position: relative;
}

.login-photo::before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: #1a2744;
    opacity: 0.4;
}

.form-title {
    font-weight: bold;
    font-size: 46px;
    line-height: 60px;
    letter-spacing: -0.322px;
    color: #fff;
    margin-bottom: 80px;
}

.login-form__actions {
    display: flex;
    align-items: center;
    justify-content: center;
}


.restore {
    margin-bottom: 54px;
    display: flex;
    align-items: center;
    justify-content: center;

    &-label {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
    }

    &-link {
        color: #ffc900;
        margin-left: 8px;
    }
}


.show-password {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
}

.info-ic {
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.btn-register {
    height: 72px;
    width: 240px;
    background: #f5f5f5;
    background: url(../../assets/images/btn-register.png) no-repeat center center;
    border-radius: 17px;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #1a2744;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
}

.btn-register:focus {
    transform: scale(1.04);
}

.btn-login {
    height: 72px;
    width: 240px;
    background: #ffc900;
    background: url(../../assets/images/btn-login.png) no-repeat center center;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #fff;
    cursor: pointer;
}

.btn-login:focus {
    transform: scale(1.04);
}

.login-slider {
    width: 400px;
    margin-left: auto;
    margin-right: 112px;
    transition: all 0.2s !important;
}

.login-slider .is-active {
    background-image: url(../../assets/images/card-active.svg) !important;
}

.login-logo {
    margin-bottom: 50px;
}

.slide {
    width: 400px;
    background-image: url(../../assets/images/card.svg);
    background-size: contain;
    background-repeat: no-repeat;
    padding: 100px 83px 98px 83px;
    text-align: center;
    outline: none !important;
}

.card-img {
    height: 241px;
    width: 166px;
    margin: 0 auto 59px auto;
}

.card-title {
    max-width: 234px;
    font-weight: 600;
    font-size: 32px;
    line-height: 46px;
    text-align: center;
    letter-spacing: -0.224px;
    color: #ffffff;
}

@media screen and (max-width: 1440px) {
    .login-wrap {
        max-width: 600px;
        padding: 40px 60px 40px 60px;
    }
}
@media screen and (max-width: 992px) {
    .login-wrap {
        max-width: 100%;
    }
}

@media screen and (max-height: 850px) {
    .login-wrap {
        /*max-width: 600px;*/
        padding: 70px 50px 40px;
    }

    .form-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
    }

    .login-logo {
        margin-bottom: 30px;
    }
    .login-form__input {
        height: 46px;
    }
    .login-form .form-control {
        margin-bottom: 30px;
    }
}

@media screen and (max-height: 700px) {
    .slide {
        height: 534px !important;
        padding: 67px 63px 73px 63px;
        background-size: contain;
    }
    .login-form .form-control {
        margin-bottom: 20px;
    }
    .login-wrap {
        padding: 40px;
    }
    .form-title {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 768px) {
    .login-photo {
        display: none;
    }

    .login-wrap {
        padding: 40px 40px 80px 40px;
        height: auto;
        /*justify-content: space-between;*/
    }

    .login-form {
        margin-top: 0;
    }
    .form-title {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 40px;
        max-width: 100%;
    }
}


@media screen and (max-width: 480px) {
    .login {
        /*height: 100%;*/
    }
    .login-wrap {
        padding: 40px 20px 80px 20px;
    }
    .form-title {
        font-size: 27px;
        line-height: 37px;
        /*text-align: center;*/
        margin-bottom: 60px;
    }
    .login .custom-button:after {
        margin-left: -70px;
        width: 120px;
        height: 36px;
        top: 15%;
    }
    .login .custom-button {
        width: 180px;
        height: 46px;
        font-size: 16px;
        line-height: 20px;
    }

}

</style>


