<template>
   <div class="sms-code">
        <form @submit.prevent="submitForm">
            <input type="hidden" name="token" :value="token">
            <h3 class="title">Введіть код із SMS</h3>
            <div class="sms-code_img">
                <img src="../assets/images/sms.svg" alt="">
            </div>
            <div class="inputs-group">
                <input
                    :id="item.id"
                    type="tel"
                    v-for="item in codeChunk"
                    v-model="item.code"
                    :key="item.id"
                    @keyup="onKey"
                    @paste="onPaste"
                    autocomplete="off"
                    ref="chunks"
                >
                <span class="error-msg" v-if="error">{{error}}</span>
            </div>
             <CustomButton label="Підтвердити"/>
        </form>
   </div>
</template>

<script>
import CustomButton from "@/components/CustomButton";
import AuthService from "@/services/AuthService";

export default {
    name: "SmsCodeForm",
    props: {
        token: {
            type: String,
            require: true
        }
    },
    components: {
        CustomButton,
    },
    data() {
        return {
            code: '',
            chunkSize: 2,
            error: '',
            focusId: null,
            codeChunk: [{
                id: 1,
                code: ''
            }, {
                id: 2,
                code: ''
            }, {
                id: 3,
                code: ''
            }]
        };
    },
    async created() {
        await this.$recaptchaLoaded();
        this.focusId = 1;
    },
    watch: {
        focusId: function () {
            if(this.focusId > 3) {
                this.focusId = 3;
                return;
            }
            else if(this.focusId < 1) {
                this.focusId = 1;
                return;
            }
            this.$refs.chunks[this.focusId - 1].focus()
        },
        codeChunk: {
            // This will let Vue know to look inside the array
            deep: true,

            handler() {
                this.codeChunk.forEach((item) => {
                    if(item.code.length > this.chunkSize) {
                        item.code = item.code.substring(0, this.chunkSize);
                    }
                    item.code = item.code.replace(/[^0-9.]/g, '');
                })
            }
        }
    },
    methods: {
        onKey(e) {
            this.focusId = e.target.id;
            let targetLen = e.target.value.length;
            if(e.key !== 'Backspace') {
                if (targetLen === this.chunkSize) {
                    this.focusId++;
                }
            }
            else {
                if (targetLen === 0) {
                    this.focusId--;
                }
            }
        },
        onPaste() {
            let paste = (event.clipboardData || window.clipboardData).getData('text');

            if(paste.match(/^\d{6}$/g)) {
                let split = paste.match(/.{1,2}/g);
                this.codeChunk[0].code = split[0];
                this.codeChunk[1].code = split[1];
                this.codeChunk[2].code = split[2];
                this.$refs.chunks[2].focus()
            }
        },
        getCode() {
            let codes = this.codeChunk.map(item => item.code);
            return codes.join('');
        },
        async submitForm() {
            let form = new FormData();

            form.append('token', this.token);
            form.append('code', this.getCode());

            this.recaptcha = await this.$recaptcha('sms');
            form.append('recaptcha', this.recaptcha);

            let resp = await AuthService.sms(form).catch(this.catchHandler);
            if(resp && resp.user) {
                //this.store.dispatch('setIdentity', resp.user);
                if(this.$router.currentRoute.query.redirect) {
                    location.href = this.$router.currentRoute.query.redirect;
                }else{
                    location.href = '/profile';
                }
            }
        },
        catchHandler(resp) {
            if(resp.statusCode === 422) {
                this.error = resp.data.code;
            }
        },
    }
}
</script>


<style lang="less" scoped>
    .title {
        font-weight: bold;
        font-size: 56px;
        line-height: 64px;
        color: #FFFFFF;
        margin-bottom: 89px;
    }
    .error-msg {
        position: absolute;
        top: 100%;
        right: 0;
        color: #ffc900;
        font-size: 16px;
        text-align: right;
    }
    .sms-code {
        margin-top: auto;
    }
    .sms-code_img {
        width: 225px;
        height: 297px;
        margin: 0 auto 40px auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .inputs-group {
        display: flex;
        justify-content: space-between;
        position: relative;
        margin-bottom: 42px;
        input {
            width: 30%;
            height: 56px;
            border-radius: 15px;
            background: transparent;
            border: 1px solid #FFC900;
            padding: 0 20px;
            outline: none;
            font-weight: normal;
            font-size: 18px;
            line-height: 24px;
            color: #F5F5F5;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            &:focus {
                background: #FFC900;
                box-shadow: 0 24px 24px rgba(249, 198, 35, 0.35);
                color: #1A2744;
            }
        }
    }
    .custom-button {
        margin: 0 auto;
        display: block;
    }


     @media all and (max-width: 1440px)  {
        .custom-button {
            line-height: 1.2;
            font-size: 24px;
            width: 240px;
            height: 52px;
            &:after {
                top: 5%;
                margin-left: -78px;
                width: 149px;
                height: 50px;
            }
        }
     }


    @media all and (max-height: 900px) {
        .title {
            font-size: 35px;
            line-height: 45px;
            margin-bottom: 40px;
        }
    }
    @media all and (max-height: 800px) {
        .sms-code_img {
            width: 180px;
            height: 200px;
        }
        .inputs-group {
            margin-bottom: 30px;
            input {
                height: 46px;
            }
        }
    }

    @media all and (max-width: 480px) {
        .sms-code_img {
            display: none;
        }
        .sms-code {
            margin-top: 0;
        }
        .title {
            margin-bottom: 80px;
                font-size: 30px;
                line-height: 40px;
        }
        .inputs-group {
            margin-bottom: 45px;
        }
        .inputs-group input {
            height: 46px;
        }
    }
</style>
